// src/components/BachPage.js

import React, { useEffect, useState } from 'react';
import { Card, CardHeader, Avatar, Typography, Box, TextField, Button, CardContent } from '@mui/material';
import DeviceTelemetry from './DeviceTelemetry';
import AgentSelection from './AgentSelection';
import BachThreads from './BachThreads';
import WifiCard from './WifiCard';
import axios from 'axios';
import useAuthToken from '../hooks/useAuthToken';
import SubscriptionCheck from './SubscriptionCheck';

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

const BachPage = ({ device, assistants, isLoadingAssistants, refreshBachs, clearSelectedDevice }) => {
  const isSpecificDevice = !!device.device_id;
  const deviceName = isSpecificDevice ? device.alias || device.device_id : device;
  const [aliasInput, setAliasInput] = useState(device.alias || '');
  const [assignedAssistant, setAssignedAssistant] = useState({ id: null, name: 'No Assistant Assigned' });
  const token = useAuthToken();

  // Fetch assigned assistant for the device or device type
  useEffect(() => {
    const fetchAssignedAssistant = async () => {
      try {
        const params = isSpecificDevice ? { device_id: device.device_id } : { device_type: device };
        const response = await axios.get(
          `${API_BASE_URL}/bach_registry/get-assistant-assignment`,
          { headers: { Authorization: `Bearer ${token}` }, params }
        );
        setAssignedAssistant({
          id: response.data.assistant_id,
          name: response.data.assistant_name || 'No Assistant Assigned',
        });
      } catch (error) {
        console.error('Error fetching assigned assistant:', error);
      }
    };
    if ((device.device_id || device.device_type) && token) fetchAssignedAssistant();
  }, [device, token, isSpecificDevice]);

  // Handle Alias Update (only applicable to specific devices)
  const handleAliasUpdate = async () => {
    try {
      await axios.put(
        `${API_BASE_URL}/bach_registry/update-bach/${device.device_id}`,
        { alias: aliasInput },
        { headers: { Authorization: `Bearer ${token}` } }
      );
      alert('Alias updated successfully');
      if (typeof refreshBachs === 'function') {
        refreshBachs(); // Trigger refresh of device list
      }
    } catch (error) {
      console.error('Error updating alias:', error);
      alert('Error updating alias');
    }
  };

  const handleDeleteBach = async () => {
    if (window.confirm(`Are you sure you want to delete ${deviceName}?`)) {
      try {
        await axios.delete(`${API_BASE_URL}/bach_registry/remove-bach/${device.device_id}`, {
          headers: { Authorization: `Bearer ${token}` },
        });
        alert(`${deviceName} deleted successfully`);
  
        // Clear the selected device and refresh listings
        if (clearSelectedDevice) {
          clearSelectedDevice();
        }
        if (refreshBachs) {
          refreshBachs();
        }
      } catch (error) {
        console.error('Error deleting bach:', error);
        alert('Error deleting bach');
      }
    }
  };
  
  

  return (
    <SubscriptionCheck>
      {/* Header Card for Device or Device Type */}
      <Card sx={{ mb: 3, backgroundColor: '#1e1e1e', boxShadow: 3 }}>
        <CardHeader
          avatar={
            <Avatar sx={{ bgcolor: 'primary.main' }}>
              {deviceName.charAt(0).toUpperCase()}
            </Avatar>
          }
          title={
            <Typography variant="h5" component="div">
              {deviceName}
            </Typography>
          }
          subheader={
            <Typography variant="body2" color="text.secondary">
              {isSpecificDevice ? `Manage your ${device.device_type} Bach` : `Manage interactions for ${device}`}
            </Typography>
          }
        />
      </Card>

      {/* Render components based on device type with device_id */}
      {(device.device_type === 'echobach_alpha' || device.device_type === 'web' || device.device_type === 'iphone') && device.device_id && (
        <>
          {/* Device Telemetry for EchoBach Devices */}
          {device.device_type === 'echobach_alpha' && (
            <Box sx={{ color: 'primary.main', mb: 3 }}>
              <DeviceTelemetry device={device} />
            </Box>
          )}

          {/* Agent Selection */}
          <AgentSelection
            device={device}
            assistants={assistants}
            isLoading={isLoadingAssistants}
            selectedAssistant={assignedAssistant.id}
            setSelectedAssistant={(id, name) => setAssignedAssistant({ id, name })}
            refreshBachs={refreshBachs}
          />

          {/* Interaction History */}
          <BachThreads deviceId={device.device_id} assistantId={assignedAssistant.id} />

          {/* Alias Assignment Card */}
          <Card sx={{ mb: 3, backgroundColor: '#1e1e1e', boxShadow: 3 }}>
            <CardHeader title={<Typography variant="h6">Assign Alias</Typography>} />
            <CardContent>
              <TextField
                value={aliasInput}
                onChange={(e) => setAliasInput(e.target.value)}
                label="Alias"
                variant="outlined"
                fullWidth
                sx={{ mb: 2 }}
              />
              <Button variant="contained" color="primary" onClick={handleAliasUpdate}>
                Save Alias
              </Button>
            </CardContent>
          </Card>

          {/* WiFi Configuration Card (specific to EchoBach) */}
          {device.device_type === 'echobach_alpha' && (
            <Box sx={{ color: 'primary.main', mt: 3 }}>
              <WifiCard device={device} />
            </Box>
          )}
            
            {/* Delete Bach Button */}
          <Card sx={{ mb: 3, backgroundColor: '#1e1e1e', boxShadow: 3 }}>
            <CardContent>
              <Button
                variant="contained"
                color="secondary"
                onClick={handleDeleteBach}
              >
                Delete Bach
              </Button>
            </CardContent>
          </Card>

        </>
      )}
    </SubscriptionCheck>
  );
};

export default BachPage;


