// src/components/UserInfoCard.js

import React, { useState, useEffect } from 'react';
import { Card, CardContent, Typography, TextField, Button, Box, Grid, CircularProgress } from '@mui/material';
import axios from 'axios';
import useAuthToken from '../hooks/useAuthToken';

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

const UserInfoCard = ({ onUserDataUpdate }) => {
  const token = useAuthToken();
  const [userData, setUserData] = useState({
    first_name: '',
    last_name: '',
    email: '',
    phone_number: '',
    address_line1: '',
    address_line2: '',
    city: '',
    state: '',
    postal_code: '',
    country: '',
  });
  const [isEditing, setIsEditing] = useState(false);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchUserData = async () => {
      if (!token) {
        setLoading(false);
        return;
      }

      try {
        const response = await axios.get(`${API_BASE_URL}/users/user-details/`, {
          headers: { Authorization: `Bearer ${token}` },
        });

        if (response.data.user) {
          const { user_id, created_at, updated_at, ...filteredUserData } = response.data.user;
          setUserData(filteredUserData);
          setLoading(false);
        } else {
          setError("User data not found.");
          setLoading(false);
        }
      } catch (err) {
        console.error("Error fetching user data:", err);
        setError("Failed to load user information.");
        setLoading(false);
      }
    };

    fetchUserData();
  }, [token]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setUserData((prevData) => ({ ...prevData, [name]: value }));
  };

  const toggleEdit = () => {
    setIsEditing(!isEditing);
    setError(null);
  };

  const handleSave = async () => {
    if (!token) {
      setError("Authentication required.");
      return;
    }
    try {
      setLoading(true);
      const response = await axios.put(`${API_BASE_URL}/users/update-user/`, userData, {
        headers: { Authorization: `Bearer ${token}` },
      });
      setUserData(response.data.user); // Update with the latest saved data
      onUserDataUpdate(response.data.user); // Callback to parent component if needed
      setIsEditing(false);
      setLoading(false);
    } catch (err) {
      console.error("Error updating user data:", err);
      setError("Failed to update user information. Please try again.");
      setLoading(false);
    }
  };

  const orderedFields = [
    'first_name',
    'last_name',
    'email',
    'phone_number',
    'address_line1',
    'address_line2',
    'city',
    'state',
    'postal_code',
    'country'
  ];

  return (
    <Card sx={{ backgroundColor: '#1e1e1e', boxShadow: 3, mb: 4 }}>
      <CardContent>
        <Box display="flex" justifyContent="space-between" alignItems="center" mb={2}>
          <Typography variant="h5" sx={{ color: 'white' }}>
            User Information
          </Typography>
          <Button variant="contained" color="primary" onClick={isEditing ? handleSave : toggleEdit} disabled={loading}>
            {loading ? <CircularProgress size={24} /> : isEditing ? "Save" : "Update"}
          </Button>
        </Box>
        {error && (
          <Typography variant="body2" color="error" gutterBottom>
            {error}
          </Typography>
        )}
        {loading ? (
          <CircularProgress />
        ) : (
          <Grid container spacing={2}>
            {isEditing
              ? orderedFields.map((field) => (
                  <Grid item xs={12} sm={field === 'address_line1' || field === 'address_line2' ? 12 : 6} key={field}>
                    <TextField
                      label={field.replace('_', ' ').replace(/\b\w/g, char => char.toUpperCase())}
                      name={field}
                      value={userData[field] || ''}
                      onChange={handleInputChange}
                      fullWidth
                      margin="normal"
                      variant="outlined"
                      color="primary"
                      sx={{ backgroundColor: '#2c2c2c', borderRadius: 1 }}
                    />
                  </Grid>
                ))
              : orderedFields.map((field) => (
                  <Grid item xs={12} sm={field === 'address_line1' || field === 'address_line2' ? 12 : 6} key={field}>
                    <Typography variant="subtitle2" color="textSecondary">
                      {field.replace('_', ' ').replace(/\b\w/g, char => char.toUpperCase())}
                    </Typography>
                    <Typography variant="body1" color="primary.main">
                      {userData[field] || 'N/A'}
                    </Typography>
                  </Grid>
                ))}
          </Grid>
        )}
      </CardContent>
    </Card>
  );
};

export default UserInfoCard;
