// src/components/DeviceOrderForm.js

import React, { useState, useEffect } from 'react';
import { Card, CardContent, Typography, TextField, Button, Box } from '@mui/material';
import axios from 'axios';
import { loadStripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';
import useAuthToken from '../hooks/useAuthToken';
import StateDropdown from './StateDropdown';
import PaymentForm from './PaymentForm';

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;
const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLISH_KEY);

const DeviceOrderForm = ({ userData, apiKey }) => {
  const token = useAuthToken();
  const [paymentMethod, setPaymentMethod] = useState(null);
  const [isAddingPaymentMethod, setIsAddingPaymentMethod] = useState(false);
  const [clientSecret, setClientSecret] = useState(null);
  const [loading, setLoading] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  
  
  const fetchPaymentMethod = async () => {
    if (!token) return;
    try {
      const response = await axios.get(`${API_BASE_URL}/stripe/get-default-payment-method/`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      console.log("Fetched payment method:", response.data);
      setPaymentMethod(response.data || null);
    } catch (error) {
      console.error("Error fetching payment method:", error);
    }
  };

  useEffect(() => {
    if (token) {
      fetchPaymentMethod();
      console.log("Fetched payment method:", paymentMethod);

    }
  }, [token]);

  const createSetupIntent = async () => {
    if (!token) return;
  
    try {
      setLoading(true);
      const response = await axios.post(
        `${API_BASE_URL}/stripe/create-setup-intent/`,
        {},
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setClientSecret(response.data.client_secret);
      setLoading(false);
      setIsAddingPaymentMethod(true);
    } catch (error) {
      console.error("Error creating SetupIntent:", error);
      setLoading(false);
    }
  };
  
  const [firstName, setFirstName] = useState(userData.first_name || '');
  const [lastName, setLastName] = useState(userData.last_name || '');
  const [quantity, setQuantity] = useState(1);
  const [discountCode, setDiscountCode] = useState('');
  const [shippingAddress, setShippingAddress] = useState({
    line1: userData.address_line1 || '',
    line2: userData.address_line2 || '',
    city: userData.city || '',
    state: userData.state || '',
    postalCode: userData.postal_code || '',
  });
  const [orderStatus, setOrderStatus] = useState('');
  const [validationStatus, setValidationStatus] = useState(null);
  const [isAddressValid, setIsAddressValid] = useState(false);
  const [totalPrice, setTotalPrice] = useState(null);
  const [discountDetails, setDiscountDetails] = useState(null); // New state for discount details

  const [unitPrice, setUnitPrice] = useState(0);

  const fetchUnitPrice = async () => {
    try {
      const response = await axios.get(`${API_BASE_URL}/order/get-price`, {
        headers: { Authorization: `Bearer ${token}` },
      });

      if (response.data && response.data.unit_amount) {
        const unitAmount = response.data.unit_amount;
        setUnitPrice(unitAmount);
        const totalAmount = unitAmount * quantity;
        setTotalPrice(totalAmount / 100); // Convert cents to dollars
      } else {
        throw new Error('Unexpected response structure');
      }
    } catch (error) {
      console.error('Error fetching unit price:', error);
      setOrderStatus('Failed to retrieve unit price. Please try again.');
    }
  };

  useEffect(() => {
    fetchUnitPrice();
  }, [token, quantity]);

  const validateDiscountCode = async () => {
    try {
      const response = await axios.post(
        `${API_BASE_URL}/order/validate-coupon`,
        { discount_code: discountCode },
        { headers: { Authorization: `Bearer ${token}` } }
      );
  
      const { valid, discount_type, discount_amount, discount_percentage, error_message } = response.data;
  
      if (valid) {
        setDiscountDetails({ discount_type, discount_amount, discount_percentage });
        // Recalculate the total price
        let newTotal = unitPrice * quantity;
        if (discount_type === 'percentage') {
          newTotal *= (1 - discount_percentage / 100);
        } else if (discount_type === 'amount') {
          newTotal = Math.max(0, newTotal - discount_amount);
        }
        setTotalPrice(newTotal / 100); // Convert cents to dollars
        setOrderStatus('Discount applied successfully.');
      } else {
        setDiscountDetails(null);
        setOrderStatus(error_message || 'Invalid discount code.');
      }
    } catch (error) {
      console.error('Error validating discount code:', error);
      setOrderStatus('Failed to validate discount code. Please try again.');
    }
  };
  

  const validateAddress = async () => {
    const { line1, line2, city, state, postalCode } = shippingAddress;
    try {
      const response = await axios.post(
        `${API_BASE_URL}/order/validate-address`,
        { line1, line2, city, state, postalCode },
        { headers: { Authorization: `Bearer ${token}` } }
      );

      const addressData = response.data?.validatedAddress;
      if (addressData) {
        setShippingAddress({
          line1: addressData.streetAddress || shippingAddress.line1,
          line2: addressData.line2 || shippingAddress.line2,
          city: addressData.city || shippingAddress.city,
          state: addressData.state || shippingAddress.state,
          postalCode: addressData.zipCode || shippingAddress.postalCode,
        });
        const formattedAddress = `${addressData.streetAddress || ""}, ${addressData.line2 || ""}, ${addressData.city || ""}, ${addressData.state || ""}, ${addressData.zipCode || ""}-${addressData.zipPlus4 || ""}`;
        setValidationStatus(`Validated Address: ${formattedAddress}`);
        setIsAddressValid(true);
      } else {
        setValidationStatus("Address validation failed: Incomplete address data.");
        setIsAddressValid(false);
      }
    } catch (error) {
      console.error("Address validation error:", error);
      setValidationStatus("Address validation failed. Please check the details.");
      setIsAddressValid(false);
    }
  };

  const sendOrderEmails = async () => {
    const headers = { Authorization: `Bearer ${token}` };
    try {
      await axios.post(
        `${API_BASE_URL}/order/send-order-confirmation`,
        {
          user_email: userData.email,
          first_name: firstName,
          last_name: lastName,
          quantity,
          discount_code: discountCode,
          shipping_address: shippingAddress,
        },
        { headers }
      );
  
      await axios.post(
        `${API_BASE_URL}/order/send-order-notification`,
        {
          user_email: userData.email,
          first_name: firstName,
          last_name: lastName,
          quantity,
          discount_code: discountCode,
          shipping_address: shippingAddress,
          api_key: apiKey,
        },
        { headers }
      );
    } catch (error) {
      console.error("Error sending order emails:", error);
    }
  };

  const handleOrderSubmit = async () => {
    if (!isAddressValid) {
      setOrderStatus("Please validate the address before placing the order.");
      return;
    }
  
    setIsSubmitting(true);
  
    try {
      const headers = { Authorization: `Bearer ${token}` };
      const paymentMethodId = paymentMethod ? paymentMethod.id : null;
  
      const response = await axios.post(
        `${API_BASE_URL}/order/create-payment-intent`,
        {
          user_email: userData.email,
          first_name: firstName,
          last_name: lastName,
          quantity,
          discount_code: discountCode,
          shipping_address: shippingAddress,
          payment_method_id: paymentMethodId,
        },
        { headers }
      );
  
      const { clientSecret, total_amount } = response.data;
      setTotalPrice(total_amount);
  
      const stripe = await stripePromise;
  
      if (paymentMethodId) {
        // Retrieve the PaymentIntent to confirm the status
        const paymentResult = await stripe.retrievePaymentIntent(clientSecret);
  
        if (paymentResult.error) {
          console.error("Error retrieving PaymentIntent:", paymentResult.error.message);
          setOrderStatus(`Error retrieving payment status: ${paymentResult.error.message}`);
          setIsSubmitting(false);
          return;
        }
  
        // Check if payment succeeded
        const status = paymentResult.paymentIntent?.status;
        if (status === "succeeded") {
          setOrderStatus("Order placed successfully and payment completed.");
          await sendOrderEmails();
        } else if (status === "requires_action") {
          setOrderStatus("Additional authentication required. Please complete the authentication.");
        } else {
          setOrderStatus("Payment processing failed. Please try again.");
          console.error(`Unexpected payment status: ${status}`);
        }
      } else {
        setOrderStatus("No payment method available. Please add a payment method.");
      }
    } catch (error) {
      console.error("Error placing order:", error);
      setOrderStatus("Failed to place order. Please try again.");
    } finally {
      setIsSubmitting(false);
    }
  };
  
  

//   const [isSubmitting, setIsSubmitting] = useState(false);

//   const handleOrderSubmit = async () => {
//     if (!isAddressValid) {
//       setOrderStatus("Please validate the address before placing the order.");
//       return;
//     }
  
//     setIsSubmitting(true);
  
//     try {
//       const headers = { Authorization: `Bearer ${token}` };
//       const paymentMethodId = paymentMethod ? paymentMethod.id : null;
//       console.log("Using paymentMethodId:", paymentMethodId);
  
//       const response = await axios.post(
//         `${API_BASE_URL}/order/create-payment-intent`,
//         {
//           user_email: userData.email,
//           first_name: firstName,
//           last_name: lastName,
//           quantity,
//           discount_code: discountCode,
//           shipping_address: shippingAddress,
//           payment_method_id: paymentMethodId,
//         },
//         { headers }
//       );
  
//       const { clientSecret, total_amount } = response.data;
//       setTotalPrice(total_amount);
  
//       const stripe = await stripePromise;

//     if (paymentMethodId) {
//     // Since PaymentIntent was confirmed server-side, retrieve it to check the status
//     const paymentResult = await stripe.retrievePaymentIntent(clientSecret);

//     if (paymentResult.error) {
//         console.error("Error retrieving PaymentIntent:", paymentResult.error.message);
//         setOrderStatus(`Error retrieving payment status: ${paymentResult.error.message}`);
//         setIsSubmitting(false);
//         return;
//     }

//     if (paymentResult.paymentIntent && paymentResult.paymentIntent.status === "succeeded") {
//         setOrderStatus("Order placed successfully and payment completed.");
//         await sendOrderEmails();
//     } else if (paymentResult.paymentIntent && paymentResult.paymentIntent.status === "requires_action") {
//         // Handle additional actions if required
//         setOrderStatus("Additional authentication required. Please complete the authentication.");
//     } else {
//         setOrderStatus("Payment processing failed. Please try again.");
//     }
//     } else {
//     setOrderStatus("No payment method available. Please add a payment method.");
//     }

//     } finally {
//       setIsSubmitting(false);
//     }
//   };
  
  
  return (
    <Card sx={{ backgroundColor: '#1e1e1e', boxShadow: 3, mb: 3 }}>
      <CardContent>
        <Typography variant="h5" gutterBottom>
          Device Order Form
        </Typography>

        {paymentMethod ? (
            <Box mt={2}>
                <Typography variant="subtitle2" color="textSecondary">
                Payment Method
                </Typography>
                <Typography variant="body1" color="primary.main">
                {paymentMethod.brand ? paymentMethod.brand.charAt(0).toUpperCase() + paymentMethod.brand.slice(1) : "Unknown"} ending in {paymentMethod.last4 || "****"}
                </Typography>
                <Button variant="outlined" color="secondary" onClick={() => setIsAddingPaymentMethod(true)} sx={{ mt: 1 }}>
                Use a different payment method
                </Button>
            </Box>
            ) : (
            <Button variant="contained" color="primary" onClick={() => setIsAddingPaymentMethod(true)} sx={{ mt: 2 }}>
                Add Payment Method
            </Button>
            )}

        {isAddingPaymentMethod && clientSecret && (
        <Elements stripe={stripePromise} options={{ clientSecret }}>
            <PaymentForm
            clientSecret={clientSecret}
            token={token}
            setIsAdding={setIsAddingPaymentMethod}
            setPaymentMethod={setPaymentMethod}
            />
        </Elements>
        )}


        <Typography variant="h6" color="primary">
        Total Price: ${totalPrice ? totalPrice.toFixed(2) : "Calculating..."}
        </Typography>

        <TextField
          label="Quantity"
          type="number"
          value={quantity}
          onChange={(e) => setQuantity(Number(e.target.value))}
          fullWidth
          margin="normal"
          variant="outlined"
          color="primary"
        />
        
        {/* Discount Code with Apply Button */}
        <Box display="flex" alignItems="center" mt={2}>
          <TextField
            label="Discount Code (optional)"
            value={discountCode}
            onChange={(e) => setDiscountCode(e.target.value)}
            fullWidth
            variant="outlined"
            color="primary"
          />
          <Button
            variant="outlined"
            color="secondary"
            onClick={validateDiscountCode} // Use validateDiscountCode to apply discount
            sx={{ ml: 2 }}
          >
            Apply Discount
          </Button>
        </Box>

        <Typography variant="h6" gutterBottom sx={{ mt: 2 }}>
          Shipping Address
        </Typography>
        <TextField
          label="First Name"
          value={firstName}
          onChange={(e) => setFirstName(e.target.value)}
          fullWidth
          margin="normal"
          variant="outlined"
          color="primary"
        />
        <TextField
          label="Last Name"
          value={lastName}
          onChange={(e) => setLastName(e.target.value)}
          fullWidth
          margin="normal"
          variant="outlined"
          color="primary"
        />
        <TextField
          label="Address Line 1"
          value={shippingAddress.line1}
          onChange={(e) => setShippingAddress({ ...shippingAddress, line1: e.target.value })}
          fullWidth
          margin="normal"
          variant="outlined"
          color="primary"
        />
        <TextField
          label="Address Line 2"
          value={shippingAddress.line2}
          onChange={(e) => setShippingAddress({ ...shippingAddress, line2: e.target.value })}
          fullWidth
          margin="normal"
          variant="outlined"
          color="primary"
        />
        <TextField
          label="City"
          value={shippingAddress.city}
          onChange={(e) => setShippingAddress({ ...shippingAddress, city: e.target.value })}
          fullWidth
          margin="normal"
          variant="outlined"
          color="primary"
        />
        <StateDropdown
          value={shippingAddress.state}
          onChange={(e) => setShippingAddress({ ...shippingAddress, state: e.target.value })}
          margin="normal"
        />
        <TextField
          label="Zip/Postal Code"
          value={shippingAddress.postalCode}
          onChange={(e) => setShippingAddress({ ...shippingAddress, postalCode: e.target.value })}
          fullWidth
          margin="normal"
          variant="outlined"
          color="primary"
        />

        <Button variant="outlined" color="secondary" onClick={validateAddress} sx={{ mt: 3 }}>
          Validate Address
        </Button>

        <Button
        variant="contained"
        color="primary"
        onClick={handleOrderSubmit}
        sx={{ mt: 3 }}
        disabled={!isAddressValid || isSubmitting}
        >
        {isSubmitting ? "Processing..." : "Place Order"}
        </Button>

        {orderStatus && (
          <Typography variant="body1" color="text.secondary" sx={{ mt: 2 }}>
            {orderStatus}
          </Typography>
        )}
        {validationStatus && (
          <Typography variant="body1" color={isAddressValid ? "primary" : "error"} sx={{ mt: 2 }}>
            {validationStatus}
          </Typography>
        )}
      </CardContent>
    </Card>
  );
};

export default DeviceOrderForm;
