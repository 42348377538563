import React, { useState, useContext } from 'react';
import axios from 'axios';
import { TextField, Button, Typography, Container, Paper, Tabs, Tab, Box } from '@mui/material';
import AuthContext from '../context/AuthContext';
import { supabase } from '../supabaseClient';

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;
console.log("API_BASE_URL: ", API_BASE_URL);

const AuthPage = () => {
  const { setUser } = useContext(AuthContext);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [addressLine1, setAddressLine1] = useState('');
  const [addressLine2, setAddressLine2] = useState('');
  const [city, setCity] = useState('');
  const [state, setState] = useState('');
  const [postalCode, setPostalCode] = useState('');
  const [country, setCountry] = useState('');
  const [tab, setTab] = useState(0);
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);

  const handleTabChange = (event, newValue) => {
    setTab(newValue);
  };

  const handlePasswordReset = async () => {
    if (!email) {
      setError('Please enter your email to reset your password.');
      return;
    }

    try {
      const { error } = await supabase.auth.resetPasswordForEmail(email);
      if (error) {
        console.error('Password reset error', error);
        setError(error.message);
      } else {
        setError('Password reset email sent successfully. Please check your email.');
      }
    } catch (error) {
      console.error('Unexpected error during password reset:', error);
      setError('An unexpected error occurred. Please try again.');
    }
  };

  const handleSignup = async () => {
    if (!email || !password || !firstName || !lastName || !addressLine1 || !city || !state || !postalCode || !country) {
      setError('Please provide all required information.');
      return;
    }

    setError('');
    setLoading(true);

    try {
      const { data, error } = await supabase.auth.signUp({
        email: email,
        password: password,
      });

      if (error) {
        console.error('Signup error', error);
        setError(error.message);
        setLoading(false);
        return;
      }

      if (data.user) {
        const { data: sessionData, error: sessionError } = await supabase.auth.getSession();
        if (sessionError) {
          console.error('Error fetching session:', sessionError);
          setError(sessionError.message);
          setLoading(false);
          return;
        }

        const token = sessionData.session?.access_token;

        if (!token) {
          setError('User is not authenticated');
          setLoading(false);
          return;
        }

        // Generate API key for the new user
        try {
          const response = await axios.post(`${API_BASE_URL}/auth/generate-api-key/`, {}, {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          });

          if (response.data && response.data.api_key) {
            console.log('API Key generated:', response.data.api_key);
          }
        } catch (error) {
          console.error('Failed to generate API key:', error);
        }

        // Create user entry in your own database
        try {
          const userInfo = {
            first_name: firstName,
            last_name: lastName,
            email: email,
            phone_number: phoneNumber,
            address_line1: addressLine1,
            address_line2: addressLine2,
            city: city,
            state: state,
            postal_code: postalCode,
            country: country,
          };

          await axios.post(`${API_BASE_URL}/users/create-user/`, userInfo, {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          });

          console.log('User created in your database');
        } catch (error) {
          console.error('Failed to create user in your database:', error);
        }

        // Set default system prompt for the new user
        try {
          const defaultPrompt = `You are a helpful assistant.`;
          await axios.post(
            `${API_BASE_URL}/interactions/interaction-settings/`,
            { system_prompt: defaultPrompt },
            {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            }
          );
          console.log('Default system prompt set');
        } catch (error) {
          console.error('Failed to set default system prompt:', error);
        }
      }
    } catch (error) {
      console.error('Unexpected signup error:', error);
      setError('An unexpected error occurred. Please try again.');
    }

    setLoading(false);
  };

  const handleLogin = async () => {
    if (!email || !password) {
      setError('Please enter both email and password.');
      return;
    }

    setError('');
    setLoading(true);
    const { data, error } = await supabase.auth.signInWithPassword({
      email: email,
      password: password,
    });

    if (error) {
      console.error('Login error', error);
      setError(error.message);
    } else {
      console.log('User logged in', data.user);
      setUser(data.user);
    }

    setLoading(false);
  };

  return (
    <Container maxWidth="sm" style={{ marginTop: '2rem' }}>
      <Paper style={{ padding: '1rem' }}>
        <Tabs value={tab} onChange={handleTabChange} centered>
          <Tab label="Login" />
          <Tab label="Signup" />
        </Tabs>
        <Box p={3}>
          {tab === 0 && (
            <>
              {/* Login Form */}
              <TextField
                label="Email"
                type="email"
                fullWidth
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                margin="normal"
              />
              <TextField
                label="Password"
                type="password"
                fullWidth
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                margin="normal"
              />
              <Button
                variant="contained"
                color="primary"
                fullWidth
                onClick={handleLogin}
                disabled={loading}
                style={{ marginTop: '1rem' }}
              >
                Log In
              </Button>
              <Button
                variant="outlined"
                color="secondary"
                fullWidth
                onClick={handlePasswordReset}
                disabled={loading}
                style={{ marginTop: '1rem' }}
              >
                Reset Password
              </Button>
            </>
          )}
          {tab === 1 && (
            <>
              {/* Signup Form */}
              <TextField
                label="First Name"
                type="text"
                fullWidth
                value={firstName}
                onChange={(e) => setFirstName(e.target.value)}
                margin="normal"
                required
              />
              <TextField
                label="Last Name"
                type="text"
                fullWidth
                value={lastName}
                onChange={(e) => setLastName(e.target.value)}
                margin="normal"
                required
              />
              <TextField
                label="Email"
                type="email"
                fullWidth
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                margin="normal"
                required
              />
              <TextField
                label="Password"
                type="password"
                fullWidth
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                margin="normal"
                required
              />
              <TextField
                label="Phone Number"
                type="text"
                fullWidth
                value={phoneNumber}
                onChange={(e) => setPhoneNumber(e.target.value)}
                margin="normal"
              />
              <TextField
                label="Address Line 1"
                type="text"
                fullWidth
                value={addressLine1}
                onChange={(e) => setAddressLine1(e.target.value)}
                margin="normal"
                required
              />
              <TextField
                label="Address Line 2"
                type="text"
                fullWidth
                value={addressLine2}
                onChange={(e) => setAddressLine2(e.target.value)}
                margin="normal"
              />
              <TextField
                label="City"
                type="text"
                fullWidth
                value={city}
                onChange={(e) => setCity(e.target.value)}
                margin="normal"
                required
              />
              <TextField
                label="State"
                type="text"
                fullWidth
                value={state}
                onChange={(e) => setState(e.target.value)}
                margin="normal"
                required
              />
              <TextField
                label="Postal Code"
                type="text"
                fullWidth
                value={postalCode}
                onChange={(e) => setPostalCode(e.target.value)}
                margin="normal"
                required
              />
              <TextField
                label="Country"
                type="text"
                fullWidth
                value={country}
                onChange={(e) => setCountry(e.target.value)}
                margin="normal"
                required
              />
              <Button
                variant="contained"
                color="primary"
                fullWidth
                onClick={handleSignup}
                disabled={loading}
                style={{ marginTop: '1rem' }}
              >
                Sign Up
              </Button>
            </>
          )}
          {error && (
            <Typography color="error" style={{ marginTop: '1rem' }}>
              {error}
            </Typography>
          )}
        </Box>
      </Paper>
    </Container>
  );
};

export default AuthPage;
