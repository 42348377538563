import React from 'react';
import { Box, Typography, Container, Paper } from '@mui/material';
import { createTheme, ThemeProvider } from '@mui/material/styles';

const theme = createTheme({
  palette: {
    mode: 'dark',
    primary: { main: '#00bcd4' },
    secondary: { main: '#ff4081' },
    background: { default: '#121212', paper: '#1e1e1e' },
    text: { primary: '#fff', secondary: '#aaa' },
  },
  typography: {
    fontFamily: 'Roboto, sans-serif',
  },
});

const PrivacyPolicy = () => {
  return (
    <ThemeProvider theme={theme}>
      <Container maxWidth="md" sx={{ mt: 5, mb: 5 }}>
        <Paper elevation={3} sx={{ padding: 4, backgroundColor: 'background.paper' }}>
          <Typography variant="h4" color="primary" gutterBottom>
            EchoBach Privacy Policy
          </Typography>
          <Typography variant="body1" color="text.secondary" paragraph>
            Effective October 2024
          </Typography>

          <Box>
            <Typography variant="h5" color="primary" gutterBottom>
              1. Introduction and Definitions
            </Typography>
            <Typography variant="body1" color="text.secondary" paragraph>
              EchoBach, Inc., a Delaware corporation (“EchoBach” or “we”), provides the EchoBach
              service, allowing users to interact with AI-powered agents through various devices
              (“bachs”). This Privacy Policy applies during the beta phase of the EchoBach service.
              As a beta product, users should expect bugs and limitations, as the product is still
              under development. For questions, please contact our founder at jason@echobach.com.
            </Typography>
            <Typography variant="body1" color="text.secondary" paragraph>
              This Privacy Policy explains what data we collect, how we use it, and the rights users
              have regarding their data. Definitions include “Beta User,” “Personal Data,”
              “Non-Personal Data,” “Data Storage,” “Third Parties,” and “Bachs” (devices or methods
              through which users access EchoBach services).
            </Typography>

            <Typography variant="h5" color="primary" gutterBottom>
              2. Information Collection
            </Typography>
            <Typography variant="body1" color="text.secondary" paragraph>
              EchoBach collects the following data from users: personal identifiers (e.g., name,
              address, email, phone number, WiFi credentials for device connectivity). Financial
              information such as credit card details is handled by Stripe, not by EchoBach. 
            </Typography>
            <Typography variant="body1" color="text.secondary" paragraph>
              Voice data collected during user interactions is processed by OpenAI and not stored by
              EchoBach. The transcriptions generated are stored solely for user reference and can be
              deleted by users at any time. Account data is collected during registration, while
              voice data is processed on each “bach” device and deleted immediately after
              transmission to OpenAI.
            </Typography>

            <Typography variant="h5" color="primary" gutterBottom>
              3. Data Usage
            </Typography>
            <Typography variant="body1" color="text.secondary" paragraph>
              EchoBach uses collected data to operate, maintain, and improve the EchoBach service.
              Personal data may also be used for essential service communications about outages,
              upgrades, or future product releases. Marketing communications regarding EchoBach
              services or products may also occur, though EchoBach will not sell or intentionally
              share personal data with third parties for external marketing.
            </Typography>
            <Typography variant="body1" color="text.secondary" paragraph>
              During the beta phase, EchoBach may analyze voice transcription data for debugging or
              other service improvements. All data collected in the use of the product is fully
              manageable by the user and may be deleted at their discretion.
            </Typography>

            <Typography variant="h5" color="primary" gutterBottom>
              4. Data Sharing and Disclosure
            </Typography>
            <Typography variant="body1" color="text.secondary" paragraph>
              User data is shared only with essential third-party providers, such as OpenAI for
              processing voice data, Heroku for server and database management, Supabase for
              authentication, and Stripe for payment processing. We limit third-party access to
              necessary data only, ensuring their compliance with strong data privacy standards.
            </Typography>
            <Typography variant="body1" color="text.secondary" paragraph>
              If additional essential third-party providers are added, EchoBach will update this
              policy and inform users. No data will be shared for advertising purposes.
            </Typography>

            <Typography variant="h5" color="primary" gutterBottom>
              5. Data Security
            </Typography>
            <Typography variant="body1" color="text.secondary" paragraph>
              All data is encrypted in transit, and servers are secured against unauthorized access.
              During the beta phase, EchoBach does not perform regular security audits. Users are
              responsible for securing their own devices and credentials. EchoBach will promptly
              notify users of any data breach affecting their data.
            </Typography>
            <Typography variant="body1" color="text.secondary" paragraph>
              Passwords and authentication tokens are managed by Supabase, while API keys are stored
              securely on Heroku servers. EchoBach operates entirely online and does not use
              physical servers for data storage or transfer.
            </Typography>

            <Typography variant="h5" color="primary" gutterBottom>
              6. User Rights
            </Typography>
            <Typography variant="body1" color="text.secondary" paragraph>
              Users may access, correct, and delete their personal information and voice
              transcriptions at any time through the account page. Data is retained as long as
              users keep it or until deletion. Deleted data is permanently removed with no backups.
            </Typography>
            <Typography variant="body1" color="text.secondary" paragraph>
              EchoBach does not provide data export services in beta but may consider adding this
              feature in the future if requested by users.
            </Typography>

            <Typography variant="h5" color="primary" gutterBottom>
              7. Cookies and Tracking
            </Typography>
            <Typography variant="body1" color="text.secondary" paragraph>
              EchoBach does not use cookies or tracking technologies in beta. Third parties like
              Cloudflare, Supabase, and Heroku may track site access for security and essential
              service provision only.
            </Typography>

            <Typography variant="h5" color="primary" gutterBottom>
              8. Data Retention
            </Typography>
            <Typography variant="body1" color="text.secondary" paragraph>
              Data is retained as long as needed to support user services or until the user deletes
              it. Once deleted, data cannot be restored. Data retention practices may change after
              beta to comply with new jurisdictional requirements.
            </Typography>

            <Typography variant="h5" color="primary" gutterBottom>
              9. International Data Transfers
            </Typography>
            <Typography variant="body1" color="text.secondary" paragraph>
              EchoBach’s third-party providers (e.g., OpenAI, Heroku) may transfer data across
              borders as needed for service functionality. Non-U.S. users are not invited to use
              EchoBach services, and any access outside the U.S. is at the user’s own risk and
              responsibility.
            </Typography>

            <Typography variant="h5" color="primary" gutterBottom>
              10. Children’s Privacy and Parental Control
            </Typography>
            <Typography variant="body1" color="text.secondary" paragraph>
              EchoBach is committed to protecting the privacy of children and complying with the Children’s Online Privacy Protection Act (COPPA). EchoBach services are designed to allow parents to set up and control their child’s access to a customized AI agent experience. The following policies apply to the collection, use, and management of data when EchoBach is used by children under the supervision of a parent or guardian.
            </Typography>
            <Typography variant="body1" color="text.secondary" paragraph>
              EchoBach does not knowingly collect, use, or disclose personal information directly from children under the age of 13 without parental consent. Parents or guardians are required to create and manage an account on behalf of their child. As account holders, parents can configure the AI agent, manage content settings, and audit the interactions between their child and the EchoBach AI.
            </Typography>
            <Typography variant="body1" color="text.secondary" paragraph>
              The information provided to and generated by the EchoBach service, including voice interactions and transcriptions, is under the full control of the parent or guardian. EchoBach does not independently access or use data related to child interactions for any purpose beyond providing the service as configured by the parent or guardian. Parents may access, delete, or modify this data at any time through their account settings.
            </Typography>
            <Typography variant="body1" color="text.secondary" paragraph>
              If a parent or guardian becomes aware that their child has provided EchoBach with personal information without parental consent, they should contact us immediately at jason@echobach.com. EchoBach will promptly delete such information from its systems.
            </Typography>
            <Typography variant="body1" color="text.secondary" paragraph>
              Parents are encouraged to monitor and control their child’s usage of the EchoBach device and services. EchoBach relies on parental oversight and control to ensure a safe and secure experience for children, and to maintain compliance with COPPA requirements.
            </Typography>
          </Box>
        </Paper>
      </Container>
    </ThemeProvider>
  );
};

export default PrivacyPolicy;
