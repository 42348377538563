// src/components/SubscriptionCheck.js
import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Typography, Box, CircularProgress } from '@mui/material';
import useAuthToken from '../hooks/useAuthToken';

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

const SubscriptionCheck = ({ children }) => {
  const [subscriptionStatus, setSubscriptionStatus] = useState(null);
  const token = useAuthToken();

  useEffect(() => {
    const checkSubscriptionStatus = async () => {
      try {
        const response = await axios.get(`${API_BASE_URL}/stripe/get-subscription-status/`, {
          headers: { Authorization: `Bearer ${token}` },
        });
        setSubscriptionStatus(response.data.status);
      } catch (error) {
        console.error('Error checking subscription status:', error);
        setSubscriptionStatus('inactive');
      }
    };

    if (token) {
      checkSubscriptionStatus();
    }
  }, [token]);

  if (subscriptionStatus === null) {
    return <CircularProgress />; // Loading spinner while checking subscription
  }

  if (subscriptionStatus !== 'active') {
    return (
      <Box sx={{ textAlign: 'center', p: 3 }}>
        <Typography variant="h6" color="error">
          You need an active subscription to access this content.
        </Typography>
        <Typography variant="body1">
          Please navigate to the account page and update your subscription.
        </Typography>
      </Box>
    );
  }

  return <>{children}</>; // Render child content if the subscription is active
};

export default SubscriptionCheck;
