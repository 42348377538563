// src/components/PaymentForm.js

import React, { useState } from 'react';
import { useStripe, useElements, PaymentElement } from '@stripe/react-stripe-js';
import { Button, CircularProgress } from '@mui/material';
import axios from 'axios';

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

const PaymentForm = ({ clientSecret, token, setIsAdding, setPaymentMethod }) => {
  const stripe = useStripe();
  const elements = useElements();
  const [loading, setLoading] = useState(false);

  const handleSavePaymentMethod = async (event) => {
    event.preventDefault();
    if (!stripe || !elements) return;

    setLoading(true);

    try {
      const { setupIntent, error } = await stripe.confirmSetup({
        elements,
        redirect: 'if_required',
      });

      if (error) {
        console.error("Error confirming setup:", error.message);
        setLoading(false);
        return;
      }

      const paymentMethodId = setupIntent.payment_method;

      await axios.post(
        `${API_BASE_URL}/stripe/confirm-setup-intent/`,
        { payment_method_id: paymentMethodId },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      const updatedPaymentMethod = await axios.get(`${API_BASE_URL}/stripe/get-default-payment-method/`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      setPaymentMethod(updatedPaymentMethod.data || null);
      setIsAdding(false);
      setLoading(false);
    } catch (error) {
      console.error("Error during payment method setup:", error);
      setLoading(false);
    }
  };

  return (
    <form onSubmit={handleSavePaymentMethod}>
      <PaymentElement />
      <Button type="submit" variant="contained" color="primary" fullWidth sx={{ mt: 2 }} disabled={loading}>
        {loading ? <CircularProgress size={24} /> : "Save Payment Method"}
      </Button>
    </form>
  );
};

export default PaymentForm;
