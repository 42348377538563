// src/components/MainContent.js
import React from 'react';
import { Box, Typography, Card, CardHeader, Avatar, CardContent } from '@mui/material';
import Talk from './Talk';
import AccountPage from './AccountPage';
import CreateNewAgent from './CreateNewAgent';
import BachPage from './BachPage';
import AgentPage from './AgentPage';
import MemoryGraph from './MemoryGraph';

const MainContent = ({
  loading,
  user,
  selectedSection,
  selectedDevice,
  selectedAgent,
  selectedWorkflow,
  selectedMemoryGraph,
  assistants,
  isLoadingAssistants,
  refreshBachs,
//   fetchBachs,
  fetchAssistants,
    clearSelectedDevice,
    clearSelectedAgent
}) => {
  return (
    <>
      {/* Talk component */}
      <Box sx={{ display: 'flex', justifyContent: 'center', mb: 2 }}>
        <Talk />
      </Box>

      {/* Main Content Rendering */}
      {loading ? (
        <Typography variant="h6">Loading...</Typography>
      ) : user ? (
        <>
          {/* Render content based on selectedSection */}
          {selectedSection === 'Account' && <AccountPage />}

          {selectedSection === 'Agents' && <CreateNewAgent onAgentCreated={fetchAssistants}/>}

          {/* Render DeviceTelemetry and InteractionHistory for EchoBach Devices */}
          {selectedDevice && (
            <BachPage
              device={selectedDevice}
              assistants={assistants}
              isLoadingAssistants={isLoadingAssistants}
              refreshBachs={refreshBachs}
              clearSelectedDevice={clearSelectedDevice}
            />
          )}

          {/* Render AgentPage when an assistant is selected */}
          {selectedAgent && (
            <AgentPage
                agent={selectedAgent}
                fetchAssistants={fetchAssistants}
                clearSelectedAgent={clearSelectedAgent} 
            />
            )}

          {/* Header Card for Selected Workflow */}
          {selectedWorkflow && (
            <Card sx={{ mb: 3, backgroundColor: '#1e1e1e', boxShadow: 3 }}>
              <CardHeader
                avatar={
                  <Avatar sx={{ bgcolor: 'secondary.main' }}>
                    {selectedWorkflow.charAt(0)}
                  </Avatar>
                }
                title={
                  <Typography variant="h5" component="div">
                    {selectedWorkflow} Workflow
                  </Typography>
                }
                subheader={
                  <Typography variant="body2" color="text.secondary">
                    Configure and monitor the {selectedWorkflow} workflow.
                  </Typography>
                }
              />
              <CardContent>
                <Typography>This is where workflow-specific information will go.</Typography>
              </CardContent>
            </Card>
          )}

          {/* Render MemoryGraph for selected device */}
          {selectedMemoryGraph && <MemoryGraph device={selectedMemoryGraph} />}

          {/* Placeholder when nothing selected */}
          {!selectedDevice &&
            !selectedAgent &&
            !selectedWorkflow &&
            !selectedSection &&
            !selectedMemoryGraph && (
              <Card sx={{ backgroundColor: '#1e1e1e', p: 2, boxShadow: 3 }}>
                <Typography variant="h6">Welcome to EchoBach Dashboard</Typography>
                <Typography>Select an item from the menu to view its details.</Typography>
              </Card>
            )}
        </>
      ) : (
        <Typography variant="h6">Please log in to access the dashboard.</Typography>
      )}
    </>
  );
};

export default MainContent;
