// src/components/CreateNewAgent.js
import React, { useState, useEffect, useContext } from 'react';
import { supabase } from '../supabaseClient';
import axios from 'axios';
import AuthContext from '../context/AuthContext';
import {
  TextField,
  Button,
  Box,
  Typography,
  Grid,
  Switch,
  FormControlLabel,
  RadioGroup,
  Radio,
  Card,
  CardContent,
  CardActions,
  CardHeader,
  Collapse,
  IconButton,
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import DeleteIcon from '@mui/icons-material/Delete';
import { styled } from '@mui/material/styles';
import SubscriptionCheck from './SubscriptionCheck';

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

const defaultPrompt = 'You are a helpful assistant.';

// Styled components
const ExpandMore = styled((props) => {
  const { expand, ...other } = props;
  return <IconButton {...other} />;
})(({ theme, expand }) => ({
  transform: expand ? 'rotate(180deg)' : 'rotate(0deg)',
  marginLeft: 'auto',
}));

const CreateNewAgent = ({ onAgentCreated }) => {
  const { user } = useContext(AuthContext);

  // State Declarations
  const [systemPrompt, setSystemPrompt] = useState(defaultPrompt);
  const [assistants, setAssistants] = useState([]);
  const [selectedAssistant, setSelectedAssistant] = useState('');
  const [newAssistantName, setNewAssistantName] = useState('');
  const [newAssistantPrompt, setNewAssistantPrompt] = useState(defaultPrompt);
  const [userName, setUserName] = useState('');
  const [userBirthDate, setUserBirthDate] = useState('');
  const [userGender, setUserGender] = useState('');
  const [tools, setTools] = useState([]);
  const [selectedTools, setSelectedTools] = useState([]);
  const [expandedTool, setExpandedTool] = useState(null); // State to track which tool is expanded
  const [recordingMode, setRecordingMode] = useState('push_to_talk'); // Default

  const backendIconUrl = `${API_BASE_URL}/static/icons/`;

  // Fetch settings when the component mounts or when the user changes
  useEffect(() => {
    fetchSettings();
    // fetchButtonSettings();
  }, [user]);

  // Functions
  const fetchSettings = async () => {
    if (user) {
      try {
        const { data } = await supabase.auth.getSession();
        const token = data.session?.access_token;

        const settingsResponse = await axios.get(`${API_BASE_URL}/interactions/interaction-settings/`, {
          headers: { Authorization: `Bearer ${token}` },
        });
        setSystemPrompt(settingsResponse.data.system_prompt);

        // await fetchAssistants();

        const toolsResponse = await axios.get(`${API_BASE_URL}/assistant/list_tools/`, {
          headers: { Authorization: `Bearer ${token}` },
        });
        setTools(toolsResponse.data.tools);

        const selectionResponse = await axios.get(`${API_BASE_URL}/assistant/get_selected_assistant/`, {
          headers: { Authorization: `Bearer ${token}` },
        });
        // setSelectedAssistant(selectionResponse.data.assistant_id);
      } catch (error) {
        console.error('Error fetching settings', error);
      }
    }
  };

  const createNewAssistant = async () => {
    try {
      const { data } = await supabase.auth.getSession();
      const token = data.session?.access_token;

      await axios.post(
        `${API_BASE_URL}/assistant/create_assistant_with_tools/`,
        {
          assistant: {
            assistant_name: newAssistantName,
            assistant_system_prompt: newAssistantPrompt,
            user_name: userName,
            birth_date: userBirthDate ? new Date(userBirthDate).toISOString().split('T')[0] : null,
            gender: userGender,
          },
          tools: selectedTools,
        },
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );

      alert('Assistant created successfully');

      // Call onAgentCreated to refresh the assistants list
      if (typeof onAgentCreated === 'function') {
        onAgentCreated();
      }

    //   await fetchAssistants(); // Refetch assistants after creation
      setNewAssistantName('');
      setNewAssistantPrompt(defaultPrompt);
      setUserName('');
      setUserBirthDate('');
      setUserGender('');
      setSelectedTools([]);
      setExpandedTool(null); // Reset expanded tool on assistant creation
    } catch (error) {
      console.error('Error creating assistant', error);
      alert('Error creating assistant');
    }
  };

  const toggleToolSelection = (toolName) => {
    if (selectedTools.includes(toolName)) {
      setSelectedTools(selectedTools.filter((t) => t !== toolName));
    } else {
      setSelectedTools([...selectedTools, toolName]);
    }
  };

  // Function to expand or collapse tool tile
  const handleToolClick = (toolName) => {
    setExpandedTool((prevTool) => (prevTool === toolName ? null : toolName));
  };

  // Render
  return (
    <SubscriptionCheck>
        <Box p={3}>
        {/* Create Custom Assistant */}
        <Card sx={{ mb: 4, backgroundColor: '#1e1e1e', boxShadow: 3 }}>
            <CardHeader
            title={<Typography variant="h5">Create Custom Agent</Typography>}
            subheader={<Typography variant="body2">Customize your agent's system prompt and tools.</Typography>}
            />
            <CardContent>
            <TextField
                label="Agent Name"
                fullWidth
                value={newAssistantName}
                onChange={(e) => setNewAssistantName(e.target.value)}
                margin="normal"
                variant="filled"
            />

            {/* Tool selection tiles in a grid layout */}
            <Typography variant="h6" gutterBottom mt={4}>
                Select Tools
            </Typography>
            <Grid container spacing={2}>
                {tools.map((tool) => {
                const isSelected = selectedTools.includes(tool.name);
                return (
                    <Grid item xs={12} sm={6} md={4} key={tool.name}>
                    <Card
                        sx={{
                        height: '100%',
                        backgroundColor: '#2a2a2a',
                        border: isSelected ? '2px solid #00bcd4' : '1px solid #444',
                        transition: '0.3s',
                        cursor: 'pointer',
                        '&:hover': {
                            borderColor: '#00bcd4',
                        },
                        }}
                        onClick={() => toggleToolSelection(tool.name)}
                    >
                        <CardContent>
                        <Box display="flex" flexDirection="column" alignItems="center">
                            <img
                            src={`${backendIconUrl}${tool.icon}`}
                            alt={tool.display_name}
                            style={{ width: '50px', height: '50px', marginBottom: '1rem' }}
                            />
                            <Typography variant="subtitle1" gutterBottom>
                            {tool.display_name || 'Unnamed Tool'}
                            </Typography>
                            {expandedTool === tool.name && (
                            <Typography variant="body2" align="center">
                                {tool.display_description}
                            </Typography>
                            )}
                        </Box>
                        </CardContent>
                        <CardActions disableSpacing>
                        <ExpandMore
                            expand={expandedTool === tool.name}
                            onClick={() => handleToolClick(tool.name)}
                            aria-expanded={expandedTool === tool.name}
                            aria-label="show more"
                        >
                            <ExpandMoreIcon />
                        </ExpandMore>
                        </CardActions>
                    </Card>
                    </Grid>
                );
                })}
            </Grid>

            {/* System prompt field */}
            <TextField
                label="Assistant System Prompt"
                multiline
                rows={4}
                fullWidth
                value={newAssistantPrompt}
                onChange={(e) => setNewAssistantPrompt(e.target.value)}
                margin="normal"
                variant="filled"
            />

            {/* Additional user details */}
            <Typography variant="subtitle1" gutterBottom mt={4}>
                User Details (Optional)
            </Typography>
            <Grid container spacing={2}>
                <Grid item xs={12} sm={4}>
                <TextField
                    label="User's Name"
                    fullWidth
                    value={userName}
                    onChange={(e) => setUserName(e.target.value)}
                    margin="normal"
                    variant="filled"
                />
                </Grid>
                <Grid item xs={12} sm={4}>
                <TextField
                    label="User's Birth Date"
                    type="date"
                    fullWidth
                    value={userBirthDate}
                    onChange={(e) => setUserBirthDate(e.target.value)}
                    margin="normal"
                    variant="filled"
                    InputLabelProps={{
                    shrink: true,
                    }}
                />
                </Grid>
                <Grid item xs={12} sm={4}>
                <TextField
                    label="User's Gender"
                    fullWidth
                    value={userGender}
                    onChange={(e) => setUserGender(e.target.value)}
                    margin="normal"
                    variant="filled"
                />
                </Grid>
            </Grid>
            </CardContent>
            <CardActions>
            <Button variant="contained" color="primary" onClick={createNewAssistant} fullWidth>
                Create Assistant
            </Button>
            </CardActions>
        </Card>
        </Box>
    </SubscriptionCheck>
  );
};

export default CreateNewAgent;
