// src/components/BachThreads.js
import React, { useState, useEffect } from 'react';
import {
  Card,
  CardHeader,
  CardContent,
  Typography,
  Box,
  IconButton,
  Button,
  Collapse,
  List,
  ListItem,
  ListItemText,
} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import axios from 'axios';
import useAuthToken from '../hooks/useAuthToken';

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

const BachThreads = ({ deviceType, deviceId, assistantId }) => {
  const [threads, setThreads] = useState([]);
  const [expandedThreadIds, setExpandedThreadIds] = useState([]);
  const [threadDetails, setThreadDetails] = useState({});
  const [loading, setLoading] = useState(true);
  const token = useAuthToken();

  useEffect(() => {
    const fetchThreads = async () => {
      setLoading(true);
      try {
        const params = {
          limit: 10,
          ...(deviceType && { device_type: deviceType }),
          ...(deviceId && { device_id: deviceId }),
          ...(assistantId && { assistant_id: assistantId }),
        };

        const response = await axios.get(`${API_BASE_URL}/bach_threads/threads/`, {
          headers: { Authorization: `Bearer ${token}` },
          params,
        });
        setThreads(response.data);
      } catch (error) {
        console.error('Error fetching threads:', error);
      } finally {
        setLoading(false);
      }
    };

    if (token) {
      fetchThreads();
    }
  }, [token, deviceType, deviceId, assistantId]);

  const handleExpandClick = (threadId) => {
    setExpandedThreadIds((prev) =>
      prev.includes(threadId)
        ? prev.filter((id) => id !== threadId)
        : [...prev, threadId]
    );
  };

  const handleDeleteThread = async (threadId) => {
    try {
      await axios.delete(`${API_BASE_URL}/bach_threads/threads/`, {
        headers: { Authorization: `Bearer ${token}` },
        data: { thread_ids: [threadId] },
      });
      setThreads((prev) => prev.filter((thread) => thread.thread_id !== threadId));
      alert('Thread deleted successfully');
    } catch (error) {
      console.error('Error deleting thread:', error);
      alert('Error deleting thread');
    }
  };

  const handleDeleteAll = async () => {
    const threadIds = threads.map((thread) => thread.thread_id);
    try {
      await axios.delete(`${API_BASE_URL}/bach_threads/threads/`, {
        headers: { Authorization: `Bearer ${token}` },
        data: { thread_ids: threadIds },
      });
      alert('All threads deleted successfully');
      setThreads([]);
      // Optionally, fetch the next 10 threads
    } catch (error) {
      console.error('Error deleting all threads:', error);
      alert('Error deleting all threads');
    }
  };

  const fetchThreadDetails = async (threadId) => {
    try {
      const response = await axios.get(`${API_BASE_URL}/bach_threads/threads/${threadId}`, {
        headers: { Authorization: `Bearer ${token}` },
      });
      setThreadDetails((prev) => ({ ...prev, [threadId]: response.data }));
    } catch (error) {
      console.error('Error fetching thread details:', error);
    }
  };

  useEffect(() => {
    expandedThreadIds.forEach((threadId) => {
      if (!threadDetails[threadId]) {
        fetchThreadDetails(threadId);
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [expandedThreadIds]);

  return (
    <Card sx={{ mb: 3, backgroundColor: '#1e1e1e', boxShadow: 3 }}>
      <CardHeader
        title={<Typography variant="h6">Interaction History</Typography>}
        subheader={
          <Typography variant="body2" color="text.secondary">
            View and manage conversation transcripts
          </Typography>
        }
        action={
          threads.length > 0 && (
            <Button variant="contained" color="secondary" onClick={handleDeleteAll}>
              Delete All
            </Button>
          )
        }
      />
      <CardContent>
        {loading ? (
          <Typography variant="body2">Loading threads...</Typography>
        ) : threads.length === 0 ? (
          <Typography variant="body2">No threads available.</Typography>
        ) : (
          threads.map((thread) => (
            <Card
              key={thread.thread_id}
              sx={{ mb: 2, backgroundColor: '#2a2a2a', boxShadow: 2 }}
            >
              <CardHeader
                title={
                  <Typography variant="subtitle1">
                    Conversation from {new Date(thread.start_time).toLocaleString()}
                  </Typography>
                }
                action={
                  <>
                    <IconButton
                      onClick={() => handleDeleteThread(thread.thread_id)}
                      aria-label="delete"
                    >
                      <DeleteIcon color="error" />
                    </IconButton>
                    <IconButton
                      onClick={() => handleExpandClick(thread.thread_id)}
                      aria-label="expand"
                    >
                      <ExpandMoreIcon
                        sx={{
                          transform: expandedThreadIds.includes(thread.thread_id)
                            ? 'rotate(180deg)'
                            : 'rotate(0deg)',
                          transition: 'transform 0.3s',
                        }}
                      />
                    </IconButton>
                  </>
                }
              />
              <Collapse
                in={expandedThreadIds.includes(thread.thread_id)}
                timeout="auto"
                unmountOnExit
              >
                <CardContent>
                  {threadDetails[thread.thread_id] ? (
                    <List>
                      {threadDetails[thread.thread_id].interactions.map(
                        (interaction, index) => (
                          <ListItem key={index} alignItems="flex-start">
                            <ListItemText
                              primary={
                                <>
                                  <Typography variant="body1" color="primary">
                                    User:
                                  </Typography>
                                  <Typography variant="body2">
                                    {interaction.user_input}
                                  </Typography>
                                </>
                              }
                              secondary={
                                <>
                                  <Typography variant="body1" color="secondary">
                                    Assistant:
                                  </Typography>
                                  <Typography variant="body2">
                                    {interaction.bot_response}
                                  </Typography>
                                </>
                              }
                            />
                          </ListItem>
                        )
                      )}
                    </List>
                  ) : (
                    <Typography variant="body2">Loading interactions...</Typography>
                  )}
                </CardContent>
              </Collapse>
            </Card>
          ))
        )}
      </CardContent>
    </Card>
  );
};

export default BachThreads;
