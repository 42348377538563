// src/components/MemoryGraph.js

import React, { useRef, useEffect } from 'react';
import { Card, CardHeader, Avatar, Typography, Box } from '@mui/material';
import { ForceGraph2D } from 'react-force-graph';

const MemoryGraph = () => {
  const fgRef = useRef();

  // Mock data for nodes and links
  const data = {
    nodes: [
      { id: 1, name: "Albert Einstein", type: "Entity" },
      { id: 2, name: "Relativity", type: "Entity" },
      { id: 3, name: "Quantum Mechanics", type: "Entity" },
      { id: 4, name: "E=mc²", type: "Proposition" },
      { id: 5, name: "Time dilation occurs at high speeds", type: "Proposition" },
      { id: 6, name: "Particles can exist in multiple states", type: "Proposition" },
      { id: 7, name: "Source: Wikipedia", type: "Evidence" },
      { id: 8, name: "Source: Physics Textbook", type: "Evidence" },
    ],
    links: [
      { source: 1, target: 4, relationship_type: "proposed" },
      { source: 1, target: 5, relationship_type: "proposed" },
      { source: 2, target: 4, relationship_type: "related_to" },
      { source: 3, target: 6, relationship_type: "related_to" },
      { source: 4, target: 7, relationship_type: "supported_by" },
      { source: 5, target: 8, relationship_type: "supported_by" },
      { source: 6, target: 7, relationship_type: "supported_by" },
    ],
  };

  const getNodeColor = (node) => {
    switch(node.type) {
      case 'Entity': return '#ff5722';
      case 'Proposition': return '#4caf50';
      case 'Evidence': return '#2196f3';
      default: return '#cccccc';
    }
  };

  const getLinkColor = (link) => {
    switch(link.relationship_type) {
      case 'proposed': return '#ff9800';
      case 'related_to': return '#9c27b0';
      case 'supported_by': return '#00bcd4';
      default: return '#b0bec5';
    }
  };

  const handleNodeHover = (node) => {
    if (node) {
      fgRef.current.canvas().style.cursor = 'pointer';
    } else {
      fgRef.current.canvas().style.cursor = 'default';
    }
  };

  return (
    <>
      {/* Header Card for Memory Graph */}
      <Card sx={{ mb: 3, backgroundColor: '#1e1e1e', boxShadow: 3 }}>
        <CardHeader
          avatar={<Avatar sx={{ bgcolor: 'secondary.main' }}>M</Avatar>}
          title={<Typography variant="h5">Memory Graph for Ellie</Typography>}
          subheader={<Typography color="text.secondary">Visualize the facts and observations encoded in the memory graph.</Typography>}
        />
      </Card>

      {/* Force Graph with max size constraints */}
      <Box
        sx={{
          maxWidth: '100%',
          maxHeight: '70vh', // Adjust max height relative to view height
          overflow: 'auto',
          borderRadius: '8px',
          padding: '10px',
          backgroundColor: '#1e1e1e',
        }}
      >
        <ForceGraph2D
          ref={fgRef}
          graphData={data}
          nodeId="id"
          nodeLabel="name"
          linkDirectionalArrowLength={6}
          linkDirectionalArrowRelPos={1}
          nodeAutoColorBy="type"
          linkColor={(link) => getLinkColor(link)}
          nodeCanvasObject={(node, ctx, globalScale) => {
            const label = node.name;
            const fontSize = 12 / globalScale;
            ctx.font = `${fontSize}px Sans-Serif`;
            ctx.fillStyle = getNodeColor(node);
            ctx.beginPath();
            ctx.arc(node.x, node.y, 5, 0, 2 * Math.PI, false);
            ctx.fill();
            ctx.fillStyle = '#ffffff';
            ctx.fillText(label, node.x + 6, node.y + 3);
          }}
          onNodeHover={handleNodeHover}
          linkWidth={2}
          enableZoomPanInteraction={true}
          width={600}   // Set fixed width or make responsive using maxWidth
          height={400}  // Set fixed height or adjust based on view constraints
        />
      </Box>
    </>
  );
};

export default MemoryGraph;
