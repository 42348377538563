// src/components/AgentPage.js
import React, { useState, useEffect, useContext } from 'react';
import { Card, CardHeader, Avatar, Typography, CardContent, Grid, Button } from '@mui/material';
import BachThreads from './BachThreads';
import axios from 'axios';
import { supabase } from '../supabaseClient';
import AuthContext from '../context/AuthContext';
import SubscriptionCheck from './SubscriptionCheck';

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

const AgentPage = ({ agent, fetchAssistants, clearSelectedAgent }) => {
  const { user } = useContext(AuthContext);
  const [tools, setTools] = useState([]);

  const backendIconUrl = `${API_BASE_URL}/static/icons/`;

  useEffect(() => {
    const fetchTools = async () => {
      const { data } = await supabase.auth.getSession();
      const token = data.session?.access_token;
      const toolsResponse = await axios.get(`${API_BASE_URL}/assistant/list_tools/`, {
        headers: { Authorization: `Bearer ${token}` },
      });
      setTools(toolsResponse.data.tools);
    };

    fetchTools();
  }, [user]);

  const handleDeleteAgent = async () => {
    if (window.confirm(`Are you sure you want to delete ${agent.assistant_name || 'this assistant'}?`)) {
      try {
        const { data } = await supabase.auth.getSession();
        const token = data.session?.access_token;
        
        await axios.delete(`${API_BASE_URL}/assistant/delete_assistant/${agent.assistant_id}`, {
          headers: { Authorization: `Bearer ${token}` },
        });
  
        alert(`${agent.assistant_name || 'Assistant'} deleted successfully`);
        
        // Clear the selected agent and refresh listings
        if (clearSelectedAgent) {
            clearSelectedAgent();
          }
          if (fetchAssistants) {
            fetchAssistants();
          }
      } catch (error) {
        console.error('Error deleting agent:', error);
        alert('Error deleting agent');
      }
    }
  };
  
  

  return (
    <SubscriptionCheck>
      {/* Header Card */}
      <Card sx={{ mb: 3, backgroundColor: '#1e1e1e', boxShadow: 3 }}>
        <CardHeader
          avatar={
            <Avatar sx={{ bgcolor: 'secondary.main' }}>
              {agent.assistant_name ? agent.assistant_name.charAt(0) : 'A'}
            </Avatar>
          }
          title={
            <Typography variant="h5" component="div">
              {agent.assistant_name || 'Unnamed Assistant'}
            </Typography>
          }
          subheader={
            <Typography variant="body2" color="text.secondary">
              Manage settings and configurations for {agent.assistant_name || 'this assistant'}.
            </Typography>
          }
        />
        <CardContent>
          {/* System Prompt */}
          <Typography variant="h6" gutterBottom>
            System Prompt
          </Typography>
          <Typography variant="body1" sx={{ mb: 2 }}>
            {agent.assistant_system_prompt}
          </Typography>

          {/* Tools */}
          <Typography variant="h6" gutterBottom>
            Tools
          </Typography>
          <Grid container spacing={2}>
            {agent.assistant_tools && agent.assistant_tools.map((toolName) => {
              const tool = tools.find(t => t.name === toolName);
              if (!tool) return null;
              return (
                <Grid item key={toolName}>
                  <img
                    src={`${backendIconUrl}${tool.icon}`}
                    alt={tool.display_name}
                    style={{ width: '50px', height: '50px' }}
                  />
                  <Typography variant="body2" align="center">
                    {tool.display_name}
                  </Typography>
                </Grid>
              );
            })}
          </Grid>
        </CardContent>
      </Card>

      {/* Interaction History */}
      <BachThreads assistantId={agent.assistant_id} />

      {/* Delete Agent Button */}
      <Card sx={{ mb: 3, backgroundColor: '#1e1e1e', boxShadow: 3 }}>
        <CardContent>
          <Button
            variant="contained"
            color="secondary"
            onClick={handleDeleteAgent}
          >
            Delete Agent
          </Button>
        </CardContent>
      </Card>
    </SubscriptionCheck>
  );
};

export default AgentPage;
