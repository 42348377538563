// src/components/DeviceTelemetry.js

import React, { useState, useEffect } from 'react';
import {
  Box,
  Typography,
  Card,
  CardContent,
  LinearProgress,
} from '@mui/material';
import PowerIcon from '@mui/icons-material/Power';
import BatteryFullIcon from '@mui/icons-material/BatteryFull';
import WifiIcon from '@mui/icons-material/Wifi';
import ThermostatIcon from '@mui/icons-material/Thermostat';
import axios from 'axios';
import useAuthToken from '../hooks/useAuthToken';
import { Line } from 'react-chartjs-2';
import 'chart.js/auto';

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

const DeviceTelemetry = ({ device }) => {
  const token = useAuthToken();
  const [batteryData, setBatteryData] = useState([]);
  const [latestData, setLatestData] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchBatteryData = async () => {
      if (!token) return;

      try {
        setLoading(true);
        const response = await axios.get(`${API_BASE_URL}/device_data/device-data`, {
          headers: { Authorization: `Bearer ${token}` },
          params: {
            start_time: new Date(Date.now() - 24 * 60 * 60 * 1000).toISOString(),
            device_id: device.device_id,
          },
        });

        const readings = response.data.readings;

        if (!Array.isArray(readings)) {
          console.error('Invalid data format: readings should be an array');
          setBatteryData([]);
          setLatestData(null);
          return;
        }

        // Parse timestamps and sort data in descending order (latest first)
        const sortedData = readings
          .map(entry => ({
            ...entry,
            timestamp: new Date(entry.timestamp),
          }))
          .sort((a, b) => b.timestamp - a.timestamp);

        setBatteryData(sortedData);

        // Set the latest telemetry data entry
        if (sortedData.length > 0) {
          setLatestData(sortedData[0]);
        } else {
          setLatestData(null);
        }
      } catch (error) {
        console.error('Error fetching battery data:', error);
        setBatteryData([]);
        setLatestData(null);
      } finally {
        setLoading(false);
      }
    };

    fetchBatteryData();
  }, [device.device_id, token]);

  // Helper function to render power source icon
  const renderPowerSourceIcon = (powerSource) =>
    powerSource === 'External Power' ? (
      <PowerIcon color="primary" />
    ) : (
      <BatteryFullIcon color="primary" />
    );

  if (loading) return <Typography variant="body2">Loading data...</Typography>;

  if (!batteryData.length)
    return (
      <Typography variant="body2">
        No telemetry data available for this device.
      </Typography>
    );

  // Ensure we have the latest data object
  const deviceData = latestData;

  return (
    <Box>
      <Card sx={{ mb: 3, backgroundColor: '#1e1e1e', boxShadow: 3 }}>
        <CardContent>
          <Typography variant="h5" color="primary">
            Device Telemetry for {device.alias || device.device_id}
          </Typography>

          {/* Battery Level with Progress Bar */}
          <Box display="flex" alignItems="center" mt={2}>
            <Typography variant="body1">Battery Level:</Typography>
            <Box width="100%" ml={2} mr={2}>
              <LinearProgress
                variant="determinate"
                value={deviceData.battery_level || 0}
                color="primary"
              />
            </Box>
            <Typography variant="body2" color="primary">
              {deviceData.battery_level != null
                ? `${deviceData.battery_level}%`
                : '0%'}
            </Typography>
          </Box>

          {/* Power Source */}
          <Box display="flex" alignItems="center" mt={2}>
            <Typography variant="body1">Power Source:</Typography>
            <Box ml={2}>{renderPowerSourceIcon(deviceData.power_source)}</Box>
            <Typography variant="body2" color="primary" ml={2}>
              {deviceData.power_source || 'Unknown'}
            </Typography>
          </Box>

          {/* Battery Temperature */}
          <Box display="flex" alignItems="center" mt={2}>
            <Typography variant="body1">Battery Temperature:</Typography>
            <ThermostatIcon color="primary" sx={{ ml: 2 }} />
            <Typography variant="body2" color="primary" ml={2}>
              {deviceData.battery_temperature != null
                ? `${deviceData.battery_temperature}°C`
                : '0°C'}
            </Typography>
          </Box>

          {/* WiFi Network */}
          <Box display="flex" alignItems="center" mt={2}>
            <Typography variant="body1">WiFi Network:</Typography>
            <WifiIcon color="primary" sx={{ ml: 2 }} />
            <Typography variant="body2" color="primary" ml={2}>
              {deviceData.network_status?.ssid || 'Unknown'} (
              {deviceData.network_status?.signal_strength || 'N/A'})
            </Typography>
          </Box>

          {/* CPU Temperature */}
          <Box display="flex" alignItems="center" mt={2}>
            <Typography variant="body1">CPU Temperature:</Typography>
            <ThermostatIcon color="primary" sx={{ ml: 2 }} />
            <Typography variant="body2" color="primary" ml={2}>
              {deviceData.cpu_temperature != null
                ? `${deviceData.cpu_temperature}°C`
                : '0°C'}
            </Typography>
          </Box>

          {/* Service Status */}
          <Box display="flex" alignItems="center" mt={2}>
            <Typography variant="body1">Service Status:</Typography>
            <Box ml={2}>
              {deviceData.service_status &&
              Object.keys(deviceData.service_status).length > 0 ? (
                Object.entries(deviceData.service_status).map(
                  ([serviceName, status]) => (
                    <Typography
                      key={serviceName}
                      variant="body2"
                      color="primary"
                    >
                      {`${serviceName}: ${status.status} (${
                        status.download_progress || 'N/A'
                      })`}
                    </Typography>
                  )
                )
              ) : (
                <Typography variant="body2" color="primary">
                  No services reported.
                </Typography>
              )}
            </Box>
          </Box>

          {/* 24-Hour Battery Level Chart */}
          {batteryData.length > 1 && (
            <Box mt={4}>
              <Line
                data={{
                  labels: batteryData.map((entry) =>
                    new Date(entry.timestamp).toLocaleTimeString()
                  ),
                  datasets: [
                    {
                      label: 'Battery Level (%) - Last 24 Hours',
                      data: batteryData.map((entry) => entry.battery_level),
                      borderColor: 'rgba(0, 188, 212, 1)', // theme primary color
                      backgroundColor: 'rgba(0, 188, 212, 0.2)',
                      fill: true,
                    },
                  ],
                }}
                options={{
                  scales: {
                    x: {
                      title: { display: true, text: 'Time' },
                      ticks: {
                        maxTicksLimit: 10,
                        autoSkip: true,
                      },
                    },
                    y: {
                      beginAtZero: true,
                      max: 100,
                      title: { display: true, text: 'Battery Level (%)' },
                    },
                  },
                }}
              />
            </Box>
          )}
        </CardContent>
      </Card>
    </Box>
  );
};

export default DeviceTelemetry;