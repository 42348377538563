// src/components/UserPaymentConfirmation.js

import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Button, Typography, Box, Card, CardContent, Container } from '@mui/material';

const PaymentConfirmation = () => {
  const location = useLocation();
  const navigate = useNavigate();

  // Extract the status from the URL query parameter, defaulting to 'failure'
  const queryParams = new URLSearchParams(location.search);
  const status = queryParams.get('status') || 'failure';

  const handleReturnToApp = () => {
    navigate('/');
  };

  return (
    <Container
      maxWidth="sm"
      sx={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        minHeight: '100vh',
      }}
    >
      <Card
        sx={{
          backgroundColor: '#1e1e1e',
          boxShadow: 3,
          padding: 4,
        }}
      >
        <CardContent>
          {status === 'success' ? (
            <>
              <Typography variant="h5" gutterBottom color="primary.main">
                Payment Method Successfully Added!
              </Typography>
              <Typography variant="body1" color="textSecondary" gutterBottom>
                Your payment method was added successfully. You will be charged $19 per month for your subscription. Additional usage fees may apply based on your model utilization, calculated daily.
              </Typography>
              <Typography variant="body2" color="textSecondary" gutterBottom>
                You can view details of your charges and manage your subscription in your account settings.
              </Typography>
            </>
          ) : (
            <>
              <Typography variant="h5" gutterBottom color="error">
                Payment Failed
              </Typography>
              <Typography variant="body1" color="textSecondary" gutterBottom>
                Unfortunately, we couldn’t add your payment method. Please check your card details or try a different payment method.
              </Typography>
            </>
          )}
          <Box mt={3} textAlign="center">
            <Button variant="contained" color="primary" onClick={handleReturnToApp}>
              Return to App
            </Button>
          </Box>
        </CardContent>
      </Card>
    </Container>
  );
};

export default PaymentConfirmation;
