// src/components/WifiCard.js
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { TextField, Button, List, ListItem, ListItemText, IconButton, Typography, Card, CardContent } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import useAuthToken from '../hooks/useAuthToken';

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

const WifiCard = () => {
  const token = useAuthToken();
  const [wifiCredentials, setWifiCredentials] = useState([]);
  const [newWifiCredential, setNewWifiCredential] = useState({ ssid: '', password: '' });
  const [showPassword, setShowPassword] = useState({});
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchWifiCredentials = async () => {
      if (!token) return;
      try {
        const response = await axios.get(`${API_BASE_URL}/wifi/get-wifi-credentials/`, {
          headers: { Authorization: `Bearer ${token}` },
        });
        setWifiCredentials(response.data.wifi_credentials || []);
        setLoading(false);
      } catch (error) {
        console.error('Error fetching WiFi credentials:', error);
        setError('Failed to load WiFi credentials.');
        setLoading(false);
      }
    };
    fetchWifiCredentials();
  }, [token]);

  const handleWifiInputChange = (e) => {
    const { name, value } = e.target;
    setNewWifiCredential((prev) => ({ ...prev, [name]: value }));
  };

  const handleAddWifiCredential = async () => {
    if (!token) return;
    try {
      const response = await axios.post(`${API_BASE_URL}/wifi/add-wifi-credential/`, newWifiCredential, {
        headers: { Authorization: `Bearer ${token}` },
      });
      setWifiCredentials([...wifiCredentials, response.data]);
      setNewWifiCredential({ ssid: '', password: '' });
    } catch (error) {
      console.error('Error adding WiFi credential:', error);
      setError('Failed to add WiFi credential.');
    }
  };

  const handleDeleteWifiCredential = async (ssid) => {
    if (!token) return;
    try {
      await axios.delete(`${API_BASE_URL}/wifi/delete-wifi-credential/`, {
        headers: { Authorization: `Bearer ${token}` },
        data: { ssid },
      });
      setWifiCredentials((prev) => prev.filter((cred) => cred.ssid !== ssid));
    } catch (error) {
      console.error('Error deleting WiFi credential:', error);
      setError('Failed to delete WiFi credential.');
    }
  };

  const togglePasswordVisibility = (ssid) => {
    setShowPassword((prevState) => ({ ...prevState, [ssid]: !prevState[ssid] }));
  };

  return (
    <Card sx={{ backgroundColor: '#1e1e1e', boxShadow: 3, mb: 4 }}>
      <CardContent>
        <Typography variant="h5" sx={{ color: 'white' }}>WiFi Credentials</Typography>
        {error && (
          <Typography variant="body2" color="error" gutterBottom>
            {error}
          </Typography>
        )}
        {loading ? (
          <Typography>Loading...</Typography>
        ) : (
          <List>
            {wifiCredentials.map((cred, index) => (
              <ListItem key={index}>
                <ListItemText
                  primary={cred.ssid}
                  secondary={showPassword[cred.ssid] ? cred.password : '********'}
                />
                <IconButton edge="end" aria-label="toggle visibility" onClick={() => togglePasswordVisibility(cred.ssid)}>
                  {showPassword[cred.ssid] ? <VisibilityOffIcon /> : <VisibilityIcon />}
                </IconButton>
                <IconButton edge="end" aria-label="delete" onClick={() => handleDeleteWifiCredential(cred.ssid)}>
                  <DeleteIcon />
                </IconButton>
              </ListItem>
            ))}
          </List>
        )}
        <TextField
          label="SSID"
          name="ssid"
          value={newWifiCredential.ssid}
          onChange={handleWifiInputChange}
          fullWidth
          margin="normal"
          variant="outlined"
          color="primary"
          sx={{ backgroundColor: '#2c2c2c', borderRadius: 1 }}
        />
        <TextField
          label="Password"
          name="password"
          type="password"
          value={newWifiCredential.password}
          onChange={handleWifiInputChange}
          fullWidth
          margin="normal"
          variant="outlined"
          color="primary"
          sx={{ backgroundColor: '#2c2c2c', borderRadius: 1 }}
        />
        <Button variant="contained" color="primary" onClick={handleAddWifiCredential} sx={{ mt: 2 }}>
          Add WiFi
        </Button>
      </CardContent>
    </Card>
  );
};

export default WifiCard;
