// src/hooks/useAuthToken.js
import { useState, useEffect } from 'react';
import { fetchSessionToken } from '../utils/fetchSessionToken';

const useAuthToken = () => {
  const [token, setToken] = useState(null);

  useEffect(() => {
    const getToken = async () => {
      const sessionToken = await fetchSessionToken();
      setToken(sessionToken);
    };
    getToken();
  }, []);

  return token;
};

export default useAuthToken;
