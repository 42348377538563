import React from 'react';
import { Box, Typography, Container, Paper } from '@mui/material';
import { createTheme, ThemeProvider } from '@mui/material/styles';

const theme = createTheme({
  palette: {
    mode: 'dark',
    primary: { main: '#00bcd4' },
    secondary: { main: '#ff4081' },
    background: { default: '#121212', paper: '#1e1e1e' },
    text: { primary: '#fff', secondary: '#aaa' },
  },
  typography: {
    fontFamily: 'Roboto, sans-serif',
  },
});

const TermsOfService = () => {
  return (
    <ThemeProvider theme={theme}>
      <Container maxWidth="md" sx={{ mt: 5, mb: 5 }}>
        <Paper elevation={3} sx={{ padding: 4, backgroundColor: 'background.paper' }}>
          <Typography variant="h4" color="primary" gutterBottom>
            EchoBach Terms of Service
          </Typography>
          <Typography variant="body1" color="text.secondary" paragraph>
            Effective Date: October 2024
          </Typography>

          <Box>
            <Typography variant="h5" color="primary" gutterBottom>
              1. Introduction and Definitions
            </Typography>
            <Typography variant="body1" color="text.secondary" paragraph>
              These Terms of Service (“ToS”) govern the use of EchoBach’s services, provided by EchoBach, Inc., a Delaware corporation. The following definitions apply:
            </Typography>
            <Typography variant="body1" color="text.secondary" paragraph>
              <ul>
                <li><strong>EchoBach, Inc.</strong>: The provider of the EchoBach services, incorporated in Delaware.</li>
                <li><strong>Services</strong>: The full suite of EchoBach offerings, including the interactive AI functionality accessible via the EchoBach hardware device (“Device”), iPhone application (“App”), and online services.</li>
                <li><strong>Beta User</strong>: A user participating in EchoBach’s beta testing phase with the understanding they may encounter bugs, errors, and other limitations.</li>
                <li><strong>User Content</strong>: Any data, voice recordings, text input, or other content that users provide.</li>
                <li><strong>OpenAI Services</strong>: The underlying AI service provided by OpenAI, which powers the interactive responses within EchoBach.</li>
                <li><strong>COPPA</strong>: The Children’s Online Privacy Protection Act, a U.S. law that applies to online services targeted to children under 13.</li>
                <li><strong>California Resident</strong>: An individual residing in California, subject to specific rights under the California Consumer Privacy Act (CCPA).</li>
              </ul>
            </Typography>

            <Typography variant="h5" color="primary" gutterBottom>
              2. Acceptance of Terms
            </Typography>
            <Typography variant="body1" color="text.secondary" paragraph>
              By using EchoBach’s services, users confirm that they have read, understood, and accepted these ToS. Users must be at least 18 years of age or, if under 18, have the consent and supervision of a parent or guardian. Users under 13 may only use the services through a parent or guardian’s account. If you do not agree to these ToS, you must discontinue your use of EchoBach immediately. EchoBach reserves the right to modify these terms at any time without prior notice. Continued use of the service signifies acceptance of any updates.
            </Typography>

            <Typography variant="h5" color="primary" gutterBottom>
              3. Accounts and Registration
            </Typography>
            <Typography variant="body1" color="text.secondary" paragraph>
              To access EchoBach’s services, users must create an account by providing accurate, current, and complete information, including a full name, valid email address, and secure password. Users agree to maintain the confidentiality of their account credentials and accept responsibility for all activities that occur under their account. Unauthorized access must be reported immediately to EchoBach. Each user is limited to one account, and accounts are restricted to users residing within the United States.
            </Typography>

            <Typography variant="h5" color="primary" gutterBottom>
              4. User Conduct and Restrictions
            </Typography>
            <Typography variant="body1" color="text.secondary" paragraph>
              Users agree to use EchoBach’s services responsibly and in compliance with applicable laws. The following activities are strictly prohibited:
            </Typography>
            <Typography variant="body1" color="text.secondary" paragraph>
              <ul>
                <li><strong>Reverse Engineering</strong>: Attempting to decompile, disassemble, or otherwise reverse engineer EchoBach’s software, firmware, or hardware.</li>
                <li><strong>Unauthorized Commercial Use</strong>: The services are intended for personal use only, except with explicit permission for commercial or institutional usage.</li>
                <li><strong>Service Interference</strong>: Actions that interfere with, disrupt, or attempt to bypass security measures or service functionality.</li>
                <li><strong>Illegal Activities</strong>: Using EchoBach for any unlawful purpose, or to transmit harmful or offensive content.</li>
              </ul>
              Accounts created on behalf of children under 13 must be supervised by a parent or guardian in compliance with COPPA.
            </Typography>
            <Typography variant="body1" color="text.secondary" paragraph>
            <strong>Parental Acknowledgment and Consent:</strong> Parents and guardians acknowledge and accept all risks associated with their minor children’s use of EchoBach’s services, including any content generated or interactions facilitated by the AI. EchoBach is not liable for any consequences arising from a minor’s use of the service under parental supervision.
            </Typography>


            <Typography variant="h5" color="primary" gutterBottom>
              5. Intellectual Property Rights
            </Typography>
            <Typography variant="body1" color="text.secondary" paragraph>
              EchoBach retains all rights, title, and interest in its services, software, hardware, trademarks, and related intellectual property. Users are granted a limited, non-exclusive, non-transferable license to use the services for personal, non-commercial purposes only. Users are prohibited from copying, reproducing, modifying, or creating derivative works based on EchoBach’s services or intellectual property without explicit permission.
            </Typography>

            <Typography variant="h5" color="primary" gutterBottom>
              6. User Content and Data Rights
            </Typography>
            <Typography variant="body1" color="text.secondary" paragraph>
              Users retain ownership of any content they submit through the EchoBach services. By submitting content, users grant EchoBach a limited license to use, store, and process this content solely for purposes related to the functioning, maintenance, and improvement of the services during the beta phase. Data handling practices are detailed in the Privacy Policy, accessible on our website.
            </Typography>

            <Typography variant="h5" color="primary" gutterBottom>
              7. Fees and Payment Terms
            </Typography>
            <Typography variant="body1" color="text.secondary" paragraph>
              EchoBach services require payment as outlined below:
            </Typography>
            <Typography variant="body1" color="text.secondary" paragraph>
              <ul>
                <li><strong>Subscription Fees:</strong> Users are billed a recurring monthly subscription fee, processed via Stripe. Subscriptions renew automatically each month unless canceled by the user.</li>
                <li><strong>Model Utilization Fees:</strong> To cover OpenAI API costs, daily usage fees are billed to the payment method on file with Stripe. These fees apply across all “bach” devices, including the iPhone app.</li>
                <li><strong>Device Purchase:</strong> Users may purchase EchoBach devices for a one-time fee. All sales are final, and devices are provided without refund or warranty. Support is limited to the beta phase.</li>
                <li><strong>Additional Device Fees:</strong> Each additional EchoBach device beyond the first incurs an extra monthly subscription fee. Future plans include tiered pricing for enterprise and educational bundles.</li>
                <li><strong>Payment Method Requirement:</strong> Users must maintain a valid payment method on file. Failure to do so may result in suspension or termination of services.</li>
                <li><strong>Non-Payment Consequences:</strong> Services will be frozen, and accounts may be deleted for non-payment. Users are responsible for all outstanding fees.</li>
                <li><strong>Fee Adjustments:</strong> EchoBach reserves the right to change fees at any time without notice.</li>
                <li><strong>Taxes and Processing Fees:</strong> Users are responsible for all applicable taxes and processing fees associated with payments made to EchoBach, managed through Stripe.</li>
                <li><strong>Refund Policy:</strong> All sales are final, and processed charges cannot be disputed. Users may cancel subscriptions anytime, effective for the next billing cycle.</li>
              </ul>
            </Typography>
            <Typography variant="body1" color="text.secondary" paragraph>
            <strong>No Refund Policy:</strong> All payments made to EchoBach, including subscription fees, model utilization fees, and device purchase fees, are non-refundable under all circumstances. Users agree that no refunds, credits, or reversals will be provided for processed payments.
            </Typography>


            <Typography variant="h5" color="primary" gutterBottom>
              8. Termination and Suspension
            </Typography>
            <Typography variant="body1" color="text.secondary" paragraph>
              EchoBach reserves the right to suspend or terminate accounts for non-payment, violations of the ToS, or activities that may harm EchoBach or its users. Upon termination, EchoBach may delete user data, and the user agrees to waive any claims against EchoBach for loss of data.
            </Typography>

            <Typography variant="h5" color="primary" gutterBottom>
              9. Warranty Disclaimer and Limitation of Liability
            </Typography>
            <Typography variant="body1" color="text.secondary" paragraph>
              <strong>Disclaimer of Warranties:</strong> EchoBach’s services, including but not limited to software, hardware, applications, and related services, are provided on an “as is,” “as available,” and “with all faults” basis. EchoBach expressly disclaims all warranties, whether express, implied, statutory, or otherwise, including but not limited to:
            </Typography>
            <Typography variant="body1" color="text.secondary" paragraph>
              <ul>
                <li><strong>Implied Warranties of Merchantability</strong>, Fitness for a Particular Purpose, and Non-Infringement**: EchoBach does not warrant that its services will meet users' specific requirements or expectations.</li>
                <li><strong>Accuracy and Reliability</strong>: EchoBach does not guarantee the accuracy, reliability, timeliness, or completeness of any information provided by or through its services.</li>
                <li><strong>Non-interruption and Error-free Operation</strong>: EchoBach does not warrant that the services will be uninterrupted, timely, secure, error-free, or free from viruses or other harmful components.</li>
              </ul>
              No oral or written information or advice provided by EchoBach or its representatives shall create any warranty.
            </Typography>
            <Typography variant="body1" color="text.secondary" paragraph>
            <strong>Third-Party Service Limitation:</strong> EchoBach’s services rely on integration with third-party services, including OpenAI. EchoBach cannot guarantee the continued availability, functionality, or performance of OpenAI’s API, and disclaims liability for any disruptions, limitations, or issues arising from OpenAI’s services.
            </Typography>

            <Typography variant="body1" color="text.secondary" paragraph>
              <strong>Limitation of Liability:</strong> To the fullest extent permitted by law, EchoBach and its officers, directors, employees, agents, licensors, and affiliates shall not be liable for any direct, indirect, incidental, special, consequential, punitive, or exemplary damages, including but not limited to damages for loss of profits, goodwill, use, data, or other intangible losses arising from or related to:
            </Typography>
            <Typography variant="body1" color="text.secondary" paragraph>
              <ul>
                <li>The <strong>use of or inability to use</strong> the services;</li>
                <li><strong>Errors, mistakes, or inaccuracies</strong> of any content;</li>
                <li><strong>Personal injury or property damage</strong> resulting from access to or use of the services;</li>
                <li><strong>Unauthorized access to or use of EchoBach’s servers and/or personal information</strong> stored therein;</li>
                <li><strong>Interruption or cessation</strong> of transmission to or from the services;</li>
                <li>Any <strong>bugs, viruses, or similar harmful elements</strong> transmitted through the services.</li>
              </ul>
              In no event shall EchoBach’s total cumulative liability for all claims related to the services exceed the amount paid by the user in the twelve (12) months preceding the event giving rise to the liability, or <strong>$100 USD</strong>, whichever is less.
            </Typography>
            <Typography variant="body1" color="text.secondary" paragraph>
            <strong>Beta Product Acknowledgement:</strong> Users acknowledge that EchoBach is currently in beta and may contain errors, bugs, and other limitations. By using the services, users accept these risks, and EchoBach shall not be held liable for any issues arising from the beta nature of the product, including but not limited to data loss, interruptions, or inaccuracies.
            </Typography>

            <Typography variant="h5" color="primary" gutterBottom>
              10. Indemnification
            </Typography>
            <Typography variant="body1" color="text.secondary" paragraph>
              Users agree to indemnify, defend, and hold harmless EchoBach, its officers, directors, employees, agents, licensors, and affiliates from any and all claims, damages, losses, liabilities, costs, and expenses (including attorneys' fees) arising from or related to: 
              <ul>
                <li>(i) Any violation of these Terms of Service by the user.</li>
                <li>(ii) Any misuse of EchoBach’s services by the user, including unauthorized or prohibited activities.</li>
                <li>(iii) Any content or materials submitted by the user that infringe upon the rights of any third party.</li>
                <li>(iv) Any violation of applicable laws, regulations, or ordinances by the user.</li>
              </ul>
              Users agree to cooperate fully with EchoBach in the defense of any claim that is subject to their indemnification obligations. EchoBach reserves the right, at its own expense, to assume the exclusive defense and control of any matter subject to indemnification. Users agree to indemnify, defend, and hold harmless EchoBach and its affiliates from any and all claims, damages, costs, losses, liabilities, and expenses, including but not limited to attorney fees, arising out of or in connection with any claim arising from their use of the service, breach of these ToS, or infringement of third-party rights, even if EchoBach opts to undertake its own defense.
            </Typography>

            <Typography variant="h5" color="primary" gutterBottom>
              11. Governing Law and Dispute Resolution
            </Typography>
            <Typography variant="body1" color="text.secondary" paragraph>
              These Terms of Service are governed by and construed in accordance with the laws of the State of Texas, without regard to its conflicts of law provisions. All disputes arising from or relating to these Terms or the services provided by EchoBach shall be resolved through binding arbitration conducted in Austin, Texas, under the rules of the American Arbitration Association (AAA). Users waive their right to bring claims in a court of law and agree to arbitration on an individual basis only, not as a class or representative action.
            </Typography>
            <Typography variant="body1" color="text.secondary" paragraph>
            <strong>Class Action Waiver:</strong> Users agree to resolve disputes on an individual basis only, and waive the right to participate in any class, collective, or representative action, including in arbitration or otherwise.
            </Typography>


            <Typography variant="h5" color="primary" gutterBottom>
              12. Miscellaneous
            </Typography>
            <Typography variant="body1" color="text.secondary" paragraph>
              These Terms of Service constitute the entire agreement between EchoBach and users, superseding all prior agreements. If any provision of these ToS is found unenforceable, the remaining provisions shall remain in effect. EchoBach’s failure to enforce any part of these ToS does not constitute a waiver.
            </Typography>

            <Typography variant="body1" color="text.secondary" paragraph>
              For questions or assistance, please contact support@echobach.com.
            </Typography>
          </Box>
        </Paper>
      </Container>
    </ThemeProvider>
  );
};

export default TermsOfService;
