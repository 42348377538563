// src/components/AppHeader.js
import React from 'react';
import {
  AppBar,
  Toolbar,
  IconButton,
  Typography,
  Menu,
  MenuItem,
  Box,
} from '@mui/material';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import MenuIcon from '@mui/icons-material/Menu';
import SettingsIcon from '@mui/icons-material/Settings';
import LogoutIcon from '@mui/icons-material/Logout';

const AppHeader = ({
  isMobile,
  handleDrawerToggle,
  anchorEl,
  handleMenuClick,
  handleMenuClose,
  handleAccountClick,
  handleLogout,
}) => {
  return (
    <>
      {/* AppBar with Menu Icon for mobile */}
      {isMobile && (
        <AppBar position="fixed">
          <Toolbar>
            <IconButton color="inherit" edge="start" onClick={handleDrawerToggle}>
              <MenuIcon />
            </IconButton>
            <Typography variant="h6" noWrap>
              EchoBach Dashboard
            </Typography>
          </Toolbar>
        </AppBar>
      )}
      {/* Settings Icon and Menu in Upper Right Corner */}
      <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
        <IconButton color="inherit" onClick={handleMenuClick}>
          <AccountCircleIcon />
        </IconButton>
        <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleMenuClose}>
          <MenuItem onClick={handleAccountClick}>
            <SettingsIcon style={{ marginRight: '8px' }} />
            Account
          </MenuItem>
          <MenuItem onClick={handleLogout}>
            <LogoutIcon style={{ marginRight: '8px' }} />
            Logout
          </MenuItem>
        </Menu>
      </Box>
    </>
  );
};

export default AppHeader;
