// src/components/AgentSelection.js
import React, { useContext, useState, useEffect } from 'react';
import {
  Box,
  Typography,
  Card,
  CardContent,
  CardHeader,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Button,
  ListItemText,
} from '@mui/material';
import axios from 'axios';
import AuthContext from '../context/AuthContext';
import useAuthToken from '../hooks/useAuthToken';

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

const AgentSelection = ({
  device,
  assistants,
  isLoading,
  refreshBachs,
}) => {
  const { user } = useContext(AuthContext);
  const token = useAuthToken();
  const [assistantId, setAssistantId] = useState('');
  const [assignedAssistant, setAssignedAssistant] = useState({
    id: null,
    name: 'No Assistant Assigned',
  });

  // Extract device_id and device_type
  const device_id = device?.device_id;
  const device_type = device?.device_type || (typeof device === 'string' ? device : null);

  // Fetch assigned assistant when component mounts or when device/token changes
  useEffect(() => {
    const fetchAssignedAssistant = async () => {
      try {
        const params = {};
        if (device_id) params.device_id = device_id;
        else if (device_type) params.device_type = device_type;

        const response = await axios.get(
          `${API_BASE_URL}/bach_registry/get-assistant-assignment`,
          {
            headers: { Authorization: `Bearer ${token}` },
            params: params,
          }
        );
        setAssignedAssistant({
          id: response.data.assistant_id,
          name: response.data.assistant_name || 'No Assistant Assigned',
        });
        setAssistantId(response.data.assistant_id || '');
      } catch (error) {
        console.error('Error fetching assigned assistant:', error);
      }
    };

    if ((device_id || device_type) && token) fetchAssignedAssistant();
  }, [device_id, device_type, token]);

  // Handle assistant selection change
  const handleChange = (event) => {
    setAssistantId(event.target.value);
  };

  // Assign selected assistant to device
  const assignAssistantToDevice = async () => {
    try {
      const data = {
        assistant_id: assistantId,
      };
      if (device_id) data.device_id = device_id;
      else if (device_type) data.device_type = device_type;

      await axios.put(
        `${API_BASE_URL}/bach_registry/update-assistant-assignment`,
        data,
        { headers: { Authorization: `Bearer ${token}` } }
      );

      const selectedAssistantObj = assistants.find(
        (assistant) => assistant.assistant_id === assistantId
      );
      const assistantName = selectedAssistantObj
        ? selectedAssistantObj.assistant_name
        : 'Unknown Assistant';

      // Update assigned assistant state
      const newAssignedAssistant = { id: assistantId, name: assistantName };
      setAssignedAssistant(newAssignedAssistant);
      alert('Assistant assigned successfully');

      if (typeof refreshBachs === 'function') {
        refreshBachs(); // Refresh the list to reflect changes
      }
    } catch (error) {
      console.error('Error assigning assistant:', error);
      alert('Error assigning assistant');
    }
  };

  return (
    <Card sx={{ mb: 4, backgroundColor: '#1e1e1e', boxShadow: 3 }}>
      <CardHeader
        title={<Typography variant="h6">Agent Assignment</Typography>}
        subheader={
          <Typography variant="body2" color="text.secondary">
            Assign an agent to this device
          </Typography>
        }
        />
      <CardContent>
        {/* Display selected assistant with blue text */}
        <Box sx={{ mb: 3 }}>
          <Typography variant="body1">
            Selected Assistant:{' '}
            <Typography
              component="span"
              sx={{ color: 'primary.main', fontWeight: 'bold' }}
            >
              {assignedAssistant.name}
            </Typography>
          </Typography>
        </Box>

        {/* Dropdown for selecting assistant */}
        <FormControl variant="outlined" fullWidth sx={{ mb: 2 }}>
          <InputLabel>Select an Assistant</InputLabel>
          <Select
            value={assistantId}
            onChange={handleChange}
            label="Select an Assistant"
          >
            {isLoading ? (
              <MenuItem value="">
                <em>Loading assistants...</em>
              </MenuItem>
            ) : assistants.length > 0 ? (
              assistants.map((assistant) => (
                <MenuItem key={assistant.assistant_id} value={assistant.assistant_id}>
                  <ListItemText
                    primary={assistant.assistant_name || 'Unnamed Assistant'}
                  />
                </MenuItem>
              ))
            ) : (
              <MenuItem value="">
                <em>No assistants available</em>
              </MenuItem>
            )}
          </Select>
        </FormControl>

        <Button
          variant="contained"
          color="primary"
          onClick={assignAssistantToDevice}
          disabled={!assistantId || assistantId === assignedAssistant.id}
          fullWidth
        >
          Assign Assistant
        </Button>
      </CardContent>
    </Card>
  );
};

export default AgentSelection;
